@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

.colors() {
  primary: @primary-color;
  gray: #666;
}
each(.colors(), {
  .c\:@{key} {
    color: @value;
  }
});

@border-radius-base: 7px;

@btn-font-weight: 500;
@btn-font-size-lg: 14px;

html {
  font-size: 14px;
  font-weight: 400;
}

body {
  background: #fafafa;
  font-family: "Inter", sans-serif;
}

.ant-form-item-label {
  text-transform: uppercase;
  & > label {
    color: #a29ea5;
    font-weight: 500;
  }
}
.ant-form-item-label > label {
  font-size: 0.9rem;
}

.ant-btn.ant-btn-lg {
  display: flex;
  align-items: center;
  .lib-icon {
    font-size: 20px;
  }
}

.ant-card-selected {
  border-color: @outline-color;
  border-width: 1px;
  border-style: solid;
  box-shadow: @input-outline-offset @outline-blur-size @outline-width
    fade(@outline-color, @outline-fade);
}

@primary-color: #373F66;